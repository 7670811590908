import { checkFinancingCarsPage, parseSubTitle } from "@/libs/utils";

type Props = {
  tag: string;
  classname: string;
  content: string;
};

const UniversalHTag = ({ tag, classname, content }: Props) => {
  if (tag == "H1") {
    return (
      <h1
        className={`${classname} text-[56px] font-[700]`}
        dangerouslySetInnerHTML={{ __html: content }}
      ></h1>
    );
  } else if (tag == "H2") {
    return (
      <h2
        className={`${classname} text-[40px] font-[700]`}
        dangerouslySetInnerHTML={{ __html: content }}
      ></h2>
    );
  } else if (tag == "H3") {
    if (checkFinancingCarsPage()) {
      return (
        <h3
          className={`${classname} text-[30px] font-[700] `}
          dangerouslySetInnerHTML={{ __html: parseSubTitle(content) }}
        ></h3>
      );
    } else {
      return (
        <h3
          className={`${classname} text-[30px] font-[700] `}
          dangerouslySetInnerHTML={{ __html: content }}
        ></h3>
      );
    }
  } else if (tag == "H4") {
    return (
      <h4
        className={`${classname}`}
        dangerouslySetInnerHTML={{ __html: content }}
      ></h4>
    );
  } else if (tag == "H5") {
    return (
      <h5
        className={classname}
        dangerouslySetInnerHTML={{ __html: content }}
      ></h5>
    );
  } else {
    return (
      <h6
        className={classname}
        dangerouslySetInnerHTML={{ __html: content }}
      ></h6>
    );
  }
};

export default UniversalHTag;
