import BestPlace from "@/components/sell/BestPlace";
import CenterBlock from "@/components/contentBlock/CenterBlock";
import RightBlock from "@/components/contentBlock/RightBlock";
import LeftBlock from "@/components/contentBlock/LeftBlock";
import { WrappedText } from "./contentBlock/WrappedText";

type Props = {
  data: any;
  allData?: any;
  index?: number;
  isArticle?: boolean;
  scrollToRunBuggy?: Function;
  authorInfo?: any;
};

const defaultScrollToRunBuggy = () => {};

const ContentBlock = ({
  data,
  allData = null,
  index = -1,
  isArticle = false,
  authorInfo,
  scrollToRunBuggy = defaultScrollToRunBuggy,
}: Props) => {
  let startNum = 0;
  let endNum = 0;
  for (let i = 0; i < index; i++) {
    if (allData[i].__component == "page-elements.columns-start") {
      startNum++;
    }
    if (allData[i].__component == "page-elements.columns-end") {
      endNum++;
    }
  }

  if (data.WrapText) {
    return <WrappedText index={index} data={data} />;
  }
  return startNum == endNum || index == -1 ? (
    data.Position == "Center" ? (
      <CenterBlock index={index} data={data} authorInfo={authorInfo} />
    ) : data.Position == "Right" ? (
      data.Image.data?.attributes.name == "best-place-to-sell-car.svg" ? (
        <BestPlace content={data.Content} image={data.Image} />
      ) : (
        <RightBlock
          data={data}
          nextData={allData != null ? allData[index + 1] : null}
          authorInfo={authorInfo}
          index={index}
        />
      )
    ) : data?.Title != "Easily list, meet, and get paid." ? (
      <LeftBlock
        data={data}
        index={index}
        authorInfo={authorInfo}
        nextData={allData != null ? allData[index + 1] : null}
        isArticle={isArticle}
        scrollToRunBuggy={scrollToRunBuggy}
      />
    ) : (
      <div></div>
    )
  ) : (
    <div></div>
  );
};

export default ContentBlock;
