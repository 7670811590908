import { useRef, useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import Image from "next/image";
import Link from "next/link";
import UniversalHTag from "@/libs/UniversalHTag";
import SliderBox from "@/components/slider/SliderBox";
import CustomDot from "@/components/slider/CustomDot";
import {
  isMobileDevice,
  checkSellPage,
  getGridRows,
  parseTitle,
  checkStatesPage,
  checkCompetitorsPage,
  checkEscrowServiceForUsedCarTransactionsPage,
  checkListingDetailsPage,
  capitalizeWords,
  checkBuyCityPage,
} from "@/libs/utils";

import "react-multi-carousel/lib/styles.css";

type Props = {
  data: any;
};

const LinkHub = ({ data }: Props) => {
  // link hub slider section
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const displayCount = Number(data?.Display || 0);
  const sliderLength = data.LinkHub?.data?.attributes?.Pages?.length || 0;

  let sliderArr: Array<number> = [];
  let count: number = isMobile ? sliderLength - 1 : sliderLength - displayCount;

  for (let i = 0; i <= count; i++) {
    sliderArr.push(Number(i));
  }

  const ref = useRef(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [autoPlay, setAutoPlay] = useState<boolean>(true);
  const [btnClicked, setBtnClicked] = useState<boolean>(true);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 925 },
      items: displayCount,
    },
    mobile: {
      breakpoint: { max: 925, min: 0 },
      items: 1,
    },
  };

  const goToPrevious = (e: any) => {
    setAutoPlay(false);
    setBtnClicked(false);
    e.preventDefault();
    if (currentSlide == 0) {
      if (isMobile) {
        // @ts-ignore
        ref.current.goToSlide(sliderLength - 1);
        setCurrentSlide(sliderLength - 1);
      } else {
        // @ts-ignore
        ref.current.goToSlide(3);
        setCurrentSlide(3);
      }
    } else {
      // @ts-ignore
      ref.current?.previous();
      setCurrentSlide(currentSlide - 1);
    }
  };

  const goToNext = (e: any) => {
    setAutoPlay(false);
    setBtnClicked(false);
    e.preventDefault();
    if (currentSlide == count) {
      // @ts-ignore
      ref.current.goToSlide(0);
      setCurrentSlide(0);
    } else {
      // @ts-ignore
      ref.current?.next();
      setCurrentSlide(currentSlide + 1);
    }
  };

  const handleSlideChange = (index: number) => {
    if (btnClicked) {
      if (isMobile) {
        if (index > sliderLength - 1) {
          setCurrentSlide(0);
        } else {
          setCurrentSlide(index);
        }
      } else {
        if (index - sliderLength - 1 < 0) {
          setCurrentSlide(0);
        } else {
          setCurrentSlide(index - sliderLength);
        }
      }
    }
  };

  const handleAfterChange = () => {
    if (btnClicked) {
      if (currentSlide === count) {
        setCurrentSlide(0);
        // @ts-ignore
        ref.current.goToSlide(0);
      }
    }
  };

  const setActive = (index: number) => {
    setAutoPlay(false);
    setBtnClicked(false);
    // @ts-ignore
    ref.current.goToSlide(index);
    setCurrentSlide(index);
  };

  useEffect(() => {
    setIsMobile(isMobileDevice());
  }, []);

  return (
    <div
      className={`w-full py-8 ${
        data.Style == "Slider"
          ? "bg-white"
          : checkStatesPage() ||
            checkCompetitorsPage() ||
            checkListingDetailsPage() ||
            checkBuyCityPage()
          ? "bg-white"
          : "bg-[#F9F9F9]"
      }`}
    >
      <div className="w-full lg:max-w-[1140px] px-[8%] lg:px-0 mx-auto">
        {data.Style == "Link" ? (
          checkSellPage() ? (
            <div
              className={`grid ${getGridRows(
                (data?.LinkHub?.data?.attributes?.Pages || []).length
              )} grid-flow-col gap-4`}
            >
              {(data?.LinkHub?.data?.attributes?.Pages || []).map(
                (item: any, i: number) => {
                  return (
                    <div
                      className="text-xl text-[#45577d] text-left font-bold underline py-2"
                      key={i}
                    >
                      {
                        <Link
                          href={
                            item.link?.data?.attributes?.slug != null
                              ? item.link?.data?.attributes.slug.slice(0, 1) ===
                                "/"
                                ? `${item.link?.data?.attributes.slug.slice(
                                    1,
                                    item.link.data.attributes.slug.length
                                  )}`
                                : `${item.link?.data?.attributes.slug}`
                              : ""
                          }
                          rel="noreferrer"
                        >
                          {item.Title}
                        </Link>
                      }
                    </div>
                  );
                }
              )}
            </div>
          ) : (
            <div>
              {data.Title != null && (
                <UniversalHTag
                  tag={data.TitleStyle}
                  classname={`${
                    checkListingDetailsPage() ? "" : "text-[#333]"
                  } font-semibold ${parseTitle(data.TitleStyle)} mb-4`}
                  content={capitalizeWords(data.Title)}
                />
              )}
              {checkBuyCityPage() && data.Title == "Browse Popular Cars" && (
                <div className="text-xl text-[#00A0C7] text-left font-medium pb-1">
                  <span className="text-[#333] font-medium">
                    These are some of our most popular
                  </span>
                  &nbsp;
                  <Link href="/buy" className="underline">
                    used cars for sale by owner.
                  </Link>
                </div>
              )}
              <div
                className={`${
                  checkEscrowServiceForUsedCarTransactionsPage()
                    ? "grid sm:grid-cols-3 py-4"
                    : data.Title ==
                      "Essential Resources for Used Car Transactions"
                    ? "grid grid-cols-1 sm:grid-cols-2 td:grid-cols-[1fr_2fr_1fr]"
                    : "grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-5"
                }`}
              >
                {(data?.LinkHub?.data?.attributes?.Pages || []).map(
                  (item: any, i: number) => {
                    if (
                      item?.__component == "linkhub.blog-link" ||
                      item?.__component == "linkhub.sell-link" ||
                      item.__component == "linkhub.external-link" ||
                      item.__component == "linkhub.buy-link"
                    ) {
                      return data.Title ==
                        "Essential Resources for Used Car Transactions" ? (
                        item.__component == "linkhub.external-link" && (
                          <div
                            key={i}
                            className={`text-xl text-[#45577d] text-left font-bold underline px-2 py-2 flex ${
                              i % 3 == 2 && "td:justify-center"
                            } ${
                              i % 3 == 0 && i != 0 && "td:justify-end"
                            } items-center`}
                          >
                            <Link href={item.URL || ""} rel="noreferrer">
                              {item.Title}
                            </Link>
                          </div>
                        )
                      ) : (
                        <div
                          className="text-xl text-[#45577d] text-left font-bold underline px-2
                          py-2"
                          key={i}
                        >
                          {item.__component == "linkhub.external-link" ? (
                            <Link
                              href={item.URL || ""}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item.Title}
                            </Link>
                          ) : data.Title == "Browse Popular Cars" ? (
                            <Link
                              href={
                                item.link?.data?.attributes?.slug != null
                                  ? `${item.link?.data?.attributes.slug.slice(
                                      1,
                                      item.link.data.attributes.slug.length
                                    )}`
                                  : ""
                              }
                              rel="noreferrer"
                            >
                              {item.Title}
                            </Link>
                          ) : (
                            <Link
                              href={
                                item.link?.data?.attributes?.slug != null
                                  ? `/${item.link?.data?.attributes.slug.slice(
                                      1,
                                      item.link.data.attributes.slug.length
                                    )}`
                                  : ""
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item.Title}
                            </Link>
                          )}
                        </div>
                      );
                    } else {
                      return "";
                    }
                  }
                )}
              </div>
            </div>
          )
        ) : data.Style == "Slider" ? (
          <div className="w-full">
            <p className={`${parseTitle(data.TitleStyle)} text-[#00b3de] py-3`}>
              {data.Title}
            </p>
            {data.Title != null && <hr className="bg-[#e6e6e6]" />}
            <div className="flex justify-center py-12">
              <div className="relative w-[90%]">
                <Carousel
                  ref={ref}
                  additionalTransfrom={15}
                  autoPlay={autoPlay}
                  autoPlaySpeed={3000}
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  arrows={false}
                  responsive={responsive}
                  ssr={true}
                  infinite={true}
                  keyBoardControl={true}
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  partialVisible={true}
                  dotListClass="custom-dot-list-style"
                  beforeChange={handleSlideChange}
                  afterChange={handleAfterChange}
                >
                  {data.LinkHub.data?.attributes?.Pages.map(
                    (item: any, index: number) => {
                      return <SliderBox key={index} data={item} />;
                    }
                  )}
                </Carousel>
                <button
                  className="absolute top-[48%] left-[-30px] hover:bg-[#e9ecef] w-[35px] h-[35px] flex justify-center items-center rounded-full cursor-pointer"
                  onClick={goToPrevious}
                >
                  <Image
                    width={10}
                    height={6}
                    src="/assets/chevron-left-disabled.svg"
                    alt="left"
                  />
                </button>
                <button
                  className="absolute top-[48%] right-[-30px] hover:bg-[#e9ecef] w-[35px] h-[35px] flex justify-center items-center rounded-full cursor-pointer"
                  onClick={goToNext}
                >
                  <Image
                    width={10}
                    height={6}
                    src="/assets/chevron-right-disabled.svg"
                    alt="right"
                  />
                </button>
                <div className="w-full flex justify-center space-x-2">
                  {sliderArr.map((index: number) => {
                    return (
                      <CustomDot
                        key={index}
                        active={index == currentSlide}
                        index={index}
                        onClick={(index: number) => setActive(index)}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="px-0 md:px-[10%]">
            {(data?.LinkHub?.data?.attributes?.Pages || []).map(
              (item: any, index: number) => {
                return (
                  <div className="block md:flex space-x-8 my-8" key={index}>
                    <div className="w-full flex md:block justify-center mb-4">
                      {item?.link?.data?.attributes?.Image?.data
                        ?.attributes && (
                        <Image
                          width={
                            item?.link?.data?.attributes?.Image?.data
                              ?.attributes?.formats.thumbnail.width
                          }
                          height={
                            item.link.data?.attributes?.Image?.data?.attributes
                              ?.formats?.thumbnail?.height
                          }
                          src={
                            item.link.data.attributes.Image.data.attributes
                              .formats.thumbnail.url
                          }
                          alt={
                            item.link.data.attributes.Image.data.attributes
                              .formats.thumbnail.name || ""
                          }
                        />
                      )}
                    </div>
                    <div>
                      <div className="text-lg text-[#1a1a1a] font-medium mb-4">
                        {item.Title}
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item?.link?.data?.attributes?.Body || "",
                        }}
                        className="text-[15px] text-[#999]"
                      ></div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LinkHub;
