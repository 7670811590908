import Image from "next/image";
type Props = {
  content: any;
  image: any;
};

const BestPlace = ({ content, image }: Props) => {
  const arr = content.split(/(?=\d{2}\n)/).filter(Boolean);
  const length = arr.length;
  let converted: Array<Array<string>> = [[]];
  for (let i = 0; i < length; i++) {
    const splitedArr = arr[i].split("\n");
    converted[i] = splitedArr;
  }
  return (
    <div className="w-full lg:max-w-[1140px] px-[8%] lg:px-0 mx-auto md:py-12">
      <div className="grid grid-cols-1 md:grid-cols-3 text-[30px] text-[#212529] md:space-x-6">
        <Image
          width={image.data?.attributes.width}
          height={image.data?.attributes.height}
          className="hidden md:block"
          src={image.data?.attributes.url}
          alt={image.data?.attributes.alternativeText || ""}
        />
        <div className="py-8 px-4 md:px-0">
          {converted.map((item: any, index: number) => {
            if (index < length / 2) {
              return (
                <div className="py-4" key={index}>
                  <p>{item[0]}</p>
                  <p className="text-[23px] font-medium">{item[1]}</p>
                  <p className="text-[18px] text-[#45577d]">{item[2]}</p>
                </div>
              );
            }
          })}
        </div>
        <div className="md:py-8 px-4 md:px-0">
          {converted.map((item: any, index: number) => {
            if (index >= length / 2) {
              return (
                <div className="py-6" key={index}>
                  <p>{item[0]}</p>
                  <p className="text-[23px] font-medium">{item[1]}</p>
                  <p className="text-[18px] text-[#45577d]">{item[2]}</p>
                </div>
              );
            }
          })}
        </div>
        <div className="flex md:hidden justify-center">
          <Image
            width={image.data?.attributes.width}
            height={image.data?.attributes.height}
            src={image.data?.attributes.url}
            alt={image.data?.attributes.alternativeText || ""}
          />
        </div>
      </div>
    </div>
  );
};

export default BestPlace;
