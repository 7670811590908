import { useState, useEffect, useRef } from "react";
import Image from "next/image";
import ContentButtons from "@/components/contentBlock/ContentButtons";
import {
  parseBgColor,
  parseTitleColor,
  parseSubTitleColor,
  parseContentColor,
  parseTitle,
  parseAlignment,
  parseContentBlockContent,
  checkHowItWorksPage,
  checkCarTransportGuidePage,
  checkSequentTwoContent,
  checkSellPage,
  checkHomePage,
  checkCompetitorsPage,
  svgLoader,
} from "@/libs/utils";
import UniversalHTag from "@/libs/UniversalHTag";
import { AuthorPageDisplay } from "../authors/AuthorPageDisplay";

type Props = {
  data: any;
  nextData: any;
  index: number;
  authorInfo?: any;
};

const RightBlock = ({ data, nextData, authorInfo, index }: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [isVideoBtnShow, setIsVideoBtnShow] = useState<boolean>(true);

  const isVideo: boolean =
    data?.Video.data != null || data?.VideoURL != null ? true : false;
  const isPoster: boolean = isVideo && data?.Image.data != null;

  const playVideo = () => {
    if (videoRef.current) {
      // @ts-ignore
      videoRef.current.play();
      setIsVideoBtnShow(false);
    }
  };

  const handleVideoStateChange = () => {
    if (videoRef.current) {
      setIsVideoBtnShow(false);
      setTimeout(() => {
        // @ts-ignore
        if (videoRef.current.paused) {
          setIsVideoBtnShow(true);
        } else {
          setIsVideoBtnShow(false);
        }
      }, 1000);
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener("timeupdate", handleVideoStateChange);
      return () => {
        videoRef.current?.removeEventListener(
          "timeupdate",
          handleVideoStateChange
        );
      };
    }
  }, []);

  return (
    <div
      className={`w-full overflow-x-hidden ${
        data.Width == "w Full" && parseBgColor(data.Color)
      } ${checkHowItWorksPage() && "sm:pt-[60px]"} ${
        !checkSellPage() && !checkSequentTwoContent(data, nextData) && "mb-8"
      }`}
    >
      <div className="relative w-full lg:max-w-[1140px] block sm:flex vs:px-[8%] lg:px-0 mx-auto z-0">
        <div
          className={`w-full ${
            checkHomePage() && "sm:h-[538px]"
          }  block sm:flex items-center ${
            data.Title != "Use Direct-to-Buyer Marketplaces"
              ? "sm:space-x-6"
              : "px-[8%] sm:px-0"
          } space-y-8 sm:space-y-0 ${
            data.Width == "w Full" && "rounded-r-xl"
          } rounded-l-xl td:rounded-l-none ${checkSellPage() && "pb-8"} ${
            checkHowItWorksPage() && "space-x-8"
          } z-10
    `}
        >
          {checkHowItWorksPage() ? (
            <div className="flex w-[300px]">
              {data?.Image?.data && (
                <Image
                  width={data.Image.data?.attributes?.width || 0}
                  height={data.Image.data?.attributes?.height || 0}
                  className="w-full z-10"
                  src={data.Image.data?.attributes?.url}
                  alt={data.Image.data?.attributes?.alternativeText || ""}
                  loader={svgLoader}
                />
              )}
            </div>
          ) : (
            <div
              className={`${
                isVideo
                  ? ""
                  : data.Title == "Use Direct-to-Buyer Marketplaces"
                  ? "w-auto sm:w-[55%]"
                  : data.Title !=
                      "2. Register on PrivateAuto and get verified" &&
                    data.Title != "4. Set your terms" &&
                    data.Title != " 6. Choose a buyer" &&
                    data.Title != "8. E-sign documents" &&
                    data.Title != "10. Transfer title"
                  ? checkCarTransportGuidePage()
                    ? "w-full sm:w-1/2"
                    : "w-[40%]"
                  : "sm:w-[280px]"
              }  ${
                isVideo
                  ? "flex"
                  : checkSellPage()
                  ? "flex flex-col-reverse sm:flex-row mt-8"
                  : "hidden sm:flex"
              }  ${
                isVideo
                  ? "justify-center sm:justify-start pt-12 sm:pt-0"
                  : "justify-center"
              } items-center`}
            >
              {isVideo ? (
                <div className="relative w-[290px] vs:w-[400px] md:w-[480px] lg:w-[645px] h-[163.6px] vs:h-[225.7px] md:h-[271px] lg:h-[364px] z-10">
                  <video
                    ref={videoRef}
                    preload="auto"
                    width={
                      data.Video.data?.attributes?.width != null
                        ? data.Video.data.attributes?.width
                        : 645
                    }
                    height={
                      data.Video.data?.attributes?.width != null
                        ? data.Video.data.attributes.height
                        : 364
                    }
                    className="w-full h-full"
                    controls={true}
                    poster={isPoster && data?.Image?.data?.attributes?.url}
                  >
                    {data?.VideoURL != null ? (
                      <source src={data.VideoURL} />
                    ) : (
                      <source
                        type={data.Video.data?.attributes?.mime}
                        src={data.Video.data?.attributes?.url}
                      />
                    )}
                  </video>
                  {isVideoBtnShow && (
                    <div className="absolute left-0 top-0 w-full h-full flex justify-center items-center">
                      <div>
                        <Image
                          width={88}
                          height={88}
                          src="/assets/video-play.svg"
                          className="cursor-pointer w-[45px] vs:w-[88px] mx-4 vs:mx-0"
                          onClick={() => playVideo()}
                          alt="video play"
                        />
                        <div className="w-[80px] vs:w-[102px] h-[32px] bg-[#2c2641] text-center text-sm vs:text-base font-semibold text-white py-1 my-2">
                          Play Video
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <Image
                  width={data.Image.data?.attributes?.width || 0}
                  height={data.Image.data?.attributes?.height || 0}
                  className={`${
                    !checkSellPage()
                      ? checkCarTransportGuidePage()
                        ? "w-full sm:h-[334px]"
                        : checkCompetitorsPage()
                        ? ""
                        : "w-[225px]"
                      : data.Title == "Use Direct-to-Buyer Marketplaces"
                      ? "w-full sm:pr-10"
                      : ""
                  } z-10`}
                  src={data.Image.data?.attributes?.url}
                  alt={data.Image.data?.attributes?.alternativeText || ""}
                  loader={svgLoader}
                />
              )}
            </div>
          )}
          <div
            className={`sm:flex-1 ${
              !checkHowItWorksPage() &&
              (isVideo
                ? "w-full sm:w-[42%] px-[2%] md:px-[3%] pb-8 sm:pb-0 flex justify-center"
                : "w-full sm:w-[60%]")
            } ${
              !isVideo &&
              !checkSellPage() &&
              !checkHowItWorksPage() &&
              "flex jusitify-center sm:justify-end px-[10%] sm:pr-[10%] md:px-[10%] pt-10 sm:pt-0"
            } space-y-4`}
          >
            <div
              className={`${
                checkSellPage() ? "w-full px-6 sm:px-0" : "w-[80%] sm:w-full"
              } flex`}
            >
              <div className="w-full z-10">
                <UniversalHTag
                  tag={data.TitleStyle}
                  classname={`${parseTitleColor(data.Color)} ${
                    data.TitleStyle != undefined &&
                    data.TitleStyle != null &&
                    parseTitle(data.TitleStyle, data.Title)
                  } ${parseAlignment(data.TitleAlignment)} z-10`}
                  content={
                    data.Title != undefined && data.Title != null
                      ? data.Title
                      : ""
                  }
                />
                {data.SubTitle != null && data?.SubTitle != undefined && (
                  <UniversalHTag
                    tag={data.SubTitleStyle}
                    classname={`${parseTitle(
                      data.SubTitleStyle,
                      data.SubTitle
                    )} ${parseSubTitleColor(data.Color)} z-10`}
                    content={data.SubTitle}
                  />
                )}
                {index === 0 && authorInfo && (
                  <AuthorPageDisplay authorInfo={authorInfo} />
                )}

                <div
                  className={`text-xl font-normal ${parseContentColor(
                    data.Color
                  )} ${parseAlignment(data.TextAlignment)} ${
                    checkSellPage() && "sm:pr-20"
                  } z-10`}
                >
                  {data.Content != null && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: parseContentBlockContent(data.Content),
                      }}
                    ></div>
                  )}
                </div>
                <ContentButtons buttonType={data.Buttons} />
              </div>
            </div>
          </div>
          {!checkSellPage() && !checkHowItWorksPage() && !isVideo && (
            <div className="w-full flex sm:hidden justify-center items-center pb-4">
              {data?.Image?.data && (
                <Image
                  width={data.Image.data?.attributes?.width || 0}
                  height={data.Image.data?.attributes?.height || 0}
                  className={`${
                    !checkSellPage()
                      ? checkCarTransportGuidePage()
                        ? "w-full sm:h-[334px]"
                        : checkCompetitorsPage()
                        ? ""
                        : "w-[225px]"
                      : ""
                  } z-10`}
                  src={data.Image.data?.attributes?.url}
                  alt={data.Image.data?.attributes?.alternativeText || ""}
                  loader={svgLoader}
                />
              )}
            </div>
          )}
        </div>
        <div
          className={`absolute left-0 top-0 w-screen h-full ${parseBgColor(
            data.Color
          )} rounded-l-xl rounded-r-xl lg:rounded-r-none`}
        ></div>
      </div>
    </div>
  );
};

export default RightBlock;
