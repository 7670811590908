import Link from "next/link";
import Image from "next/image";
import {
  checkHomePage,
  checkHowItWorksPage,
  checkAboutPage,
  checkCarTransportGuidePage,
  checkEscrowServiceForUsedCarTransactionsPage,
  checkFinancingCarsPage,
} from "@/libs/utils";

type Props = {
  buttonType: string;
  color?: string;
  isInterested?: boolean;
};

const ContentButtons = ({
  buttonType,
  color = "",
  isInterested = false,
}: Props) => {
  return (
    <div
      className={`flex ${
        checkCarTransportGuidePage() ||
        checkEscrowServiceForUsedCarTransactionsPage()
          ? "justify-center"
          : ""
      }`}
    >
      {buttonType == "Sell & Browse" ? (
        <div className="flex space-x-2 sm:space-x-8 py-8 z-10">
          <Link href={`/sell`}>
            <button
              className={`${
                color == "Dark Blue"
                  ? "bg-white text-[#00b3de] border border-[#00b3de]"
                  : "bg-[#00b3de] text-white"
              }  text-base font-normal py-2 px-1 xs:px-3 rounded cursor-pointer`}
            >
              Sell a vehicle
            </button>
          </Link>
          <Link href={`/buy`}>
            <button
              className={`${
                color == "Dark Blue"
                  ? "bg-white text-[#00b3de] border border-[#00b3de]"
                  : "bg-[#eff0f6] text-black"
              } text-base font-normal  py-2 px-1 xs:px-3 rounded cursor-pointer`}
            >
              <div className="flex">
                <span>Browse Vehicles </span>
                <Image
                  width={24}
                  height={24}
                  src="/assets/arrowRight.svg"
                  alt="arrowRight"
                />
              </div>
            </button>
          </Link>
        </div>
      ) : buttonType == "Sell" ? (
        checkAboutPage() ? (
          <div className="w-full flex justify-center">
            <Link href={`/sell`}>
              <button
                className={`bg-[#00b3de] text-white text-base font-semibold py-2 px-3 rounded-lg cursor-pointer my-4`}
              >
                Start Your Listing Today
              </button>
            </Link>
          </div>
        ) : (
          <div
            className={`${
              (checkHowItWorksPage() || checkHomePage()) && "w-full"
            } block pb-10 z-10`}
          >
            <Link href={`/sell`}>
              <button
                className={`${checkHomePage() && "w-full sm:w-auto"} ${
                  color == "Dark Blue"
                    ? "bg-white text-[#00b3de] border border-[#00b3de]"
                    : "bg-[#00b3de] text-white"
                } text-base font-normal py-2 px-3 rounded cursor-pointer`}
              >
                {checkHowItWorksPage()
                  ? "Sell my vehicle"
                  : "Sell your vehicle"}
              </button>
            </Link>
          </div>
        )
      ) : buttonType == "Browse" ? (
        <div className="w-full z-10">
          {isInterested ? (
            <div className="block sm:flex justify-center items-center space-x-4 pt-6 pb-12">
              <p className="text-base text-white font-semibold mb-8 sm:mb-0">
                Interested in buying a used car?
              </p>
              <Link href={`/buy`}>
                <button
                  className={`${
                    color == "Dark Blue"
                      ? "bg-white text-[#00b3de] border border-[#00b3de] font-semibold"
                      : "bg-[#00b3de] text-white font-normal"
                  } text-base py-2 px-3 rounded cursor-pointer`}
                >
                  Browse cars
                </button>
              </Link>
            </div>
          ) : (
            <div className={`${checkFinancingCarsPage() ? "hidden" : "pt-12"}`}>
              <Link href={`/buy`}>
                <button
                  className={`${
                    color == "Dark Blue"
                      ? "bg-white text-[#00b3de] border border-[#00b3de]"
                      : "bg-[#00b3de] text-white"
                  } text-base font-normal py-2 px-3 rounded cursor-pointer`}
                >
                  Browse Vehicles
                </button>
              </Link>
            </div>
          )}
        </div>
      ) : buttonType == "App Stores" ? (
        <div className="flex justify-center sm:justify-start mt-8 space-x-8 z-10">
          <Link
            href="https://apps.apple.com/us/app/privateauto-sell-privately/id1614271597"
            target="_blank"
            rel="noreferrer"
          >
            <Image
              width={132}
              height={45}
              src="/assets/appstore.svg"
              alt="appstore"
            />
          </Link>
          <Link
            href="https://play.google.com/store/apps/details?id=io.gonative.android.qwkoyk"
            target="_blank"
            rel="noreferrer"
          >
            <Image
              width={137}
              height={49}
              src="/assets/googleplay.svg"
              alt="googleplay"
            />
          </Link>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ContentButtons;
