import Image from "next/image";
import Link from "next/link";

type Props = {
  data: any;
};

const SliderBox = ({ data }: Props) => {
  const getImageUrl = (dataInfo: any) => {
    const jumpLink = dataInfo.link.data?.attributes?.Content.find(
      (element: any) => element.__component === "page-elements.jump-links"
    );
    return jumpLink?.Image?.data?.attributes?.url;
  };

  return (
    <div className="pr-10">
      <Link href={data.link.data?.attributes?.slug ?? ""}>
        <Image
          width={350}
          height={200}
          src={
            data.link.data?.attributes?.Image?.data?.attributes?.url
              ? data.link.data?.attributes?.Image?.data?.attributes?.url
              : getImageUrl(data)
          }
          className="object-cover cursor-pointer md:h-[162px]"
          alt={
            data.link.data?.attributes?.Image.data?.attributes
              ?.alternativeText || ""
          }
        />
      </Link>
      <div className="py-8">
        <Link
          href={data.link.data?.attributes.slug ?? ""}
          className="text-lg text-black hover:text-[#00b3de] hover:underline font-medium"
        >
          {data.link.data?.attributes.PageTitle
            ? data.link.data?.attributes.PageTitle
            : data.Title}
        </Link>
      </div>
    </div>
  );
};

export default SliderBox;
