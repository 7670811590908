import Image from "next/image";
import ContentButtons from "@/components/contentBlock/ContentButtons";
import {
  parseBgColor,
  parseTitleColor,
  parseSubTitleColor,
  parseContentColor,
  parseTitle,
  parseAlignment,
  parseContentBlockContent,
  checkHowItWorksPage,
  checkUsedCarWarrantyGuidePage,
  checkSellPage,
  checkAboutPage,
  checkTranportCarsPage,
  svgLoader,
  parseCTAColor,
  convertCTAAction,
  checkInsuranceCarsPage,
} from "@/libs/utils";
import UniversalHTag from "@/libs/UniversalHTag";
import Link from "next/link";
import CTA from "../CTA";
import { AuthorPageDisplay } from "../authors/AuthorPageDisplay";

type Props = {
  data: any;
  authorInfo?: any;
  index: number;
};

const CenterBlock = ({ data, authorInfo, index }: Props) => {
  return (
    <div
      className={`${
        data.Width == "w Full" && parseBgColor(data.Background)
      } block sm:flex items-center ${
        data.Title == "How to Sell My Used Car in Each State?" &&
        "justify-center"
      } space-x-0 sm:space-x-8 sm:space-y-0 ${
        index === 0 ? "pt-5" : ""
      } text-[#333] ${
        data.Title != "Have a vehicle you want to sell?"
          ? data.Title == "How It Works"
            ? "pt-[20px]"
            : checkHowItWorksPage()
            ? "sm:pt-[60px] sm:pb-8"
            : !checkTranportCarsPage() && "pt-[56px]"
          : ""
      } ${
        checkTranportCarsPage() &&
        (data.Title == "How Our Car Transport Service Works" ? "pt-8" : "pb-8")
      }`}
    >
      <div
        className={`relative w-full ${
          checkAboutPage() ? "lg:max-w-[776px]" : "lg:max-w-[1140px]"
        } block sm:flex ${
          data.Title == "How to Sell My Used Car in Each State?" &&
          "justify-center"
        } px-[8%] lg:px-0 mx-auto z-0`}
      >
        {checkHowItWorksPage() && data?.Image?.data && (
          <Image
            width={data.Image.data?.attributes?.width}
            height={data.Image.data?.attributes?.height}
            className="block sm:hidden w-[300px]"
            src={data.Image.data?.attributes?.url}
            alt="term"
            loader={svgLoader}
          />
        )}
        {checkSellPage() && data?.Image?.data != null && (
          <div className="flex justify-center pb-8">
            <Image
              width={data.Image.data?.attributes?.width}
              height={data.Image.data?.attributes?.height}
              className="block sm:hidden"
              src={data.Image.data?.attributes?.url}
              alt="term"
              loader={svgLoader}
            />
          </div>
        )}
        <div
          className={`flex-1 ${
            checkUsedCarWarrantyGuidePage() ? "sm:px-8" : ""
          } ${
            data.Image.data == null && data.Content == null
              ? "w-full sm:px-[8%]"
              : data.Title == "How to Sell My Used Car in Each State?"
              ? "w-full md:w-[70%]"
              : "w-full"
          } text-center ${
            data.Title == "How to Sell My Used Car in Each State?" &&
            "sm:text-left"
          } space-y-4 ${checkHowItWorksPage() && "px-0 sm:px-8 pt-8"} ${
            checkInsuranceCarsPage() && "pb-4"
          }`}
        >
          {data?.Title != null && (
            <div>
              <UniversalHTag
                tag={data.TitleStyle}
                classname={`max-w-[720px] ${parseTitleColor(
                  data.Color
                )} ${parseAlignment(data.TitleAlignment, data.Title)} ${
                  data.TitleStyle != null && parseTitle(data.TitleStyle)
                } ${checkSellPage() && "w-full sm:w-[590px]"} m-0 mx-auto`}
                content={
                  data.Title != undefined && data.Title != null
                    ? data.Title
                    : ""
                }
              />
              {index === 0 && authorInfo && (
                <AuthorPageDisplay authorInfo={authorInfo} />
              )}
            </div>
          )}
          {data.SubTitle != null && data?.SubTitle != undefined && (
            <UniversalHTag
              tag={data.SubTitleStyle}
              classname={`${parseTitle(
                data.SubTitleStyle
              )} ${parseSubTitleColor(data.Color)} ${
                checkAboutPage() && "sm:px-[20%]"
              }`}
              content={data.SubTitle}
            />
          )}
          {data.Content != null && (
            <div
              className={`text-xl font-normal ${parseAlignment(
                data.TextAlignment
              )} ${parseContentColor(data.Color)} ${
                data.Title == "Where Can I Sell My Car for Cash Today?" &&
                "text-left sm:px-20"
              }`}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: parseContentBlockContent(data.Content),
                }}
                className={`${
                  data.TitleAlignment == "Center"
                    ? checkUsedCarWarrantyGuidePage()
                      ? "w-full"
                      : checkSellPage()
                      ? "max-w-[694px] mx-auto"
                      : ""
                    : "max-w-[720px] mx-auto"
                }`}
              ></div>
            </div>
          )}
          {data?.Buttons != "None" && (
            <ContentButtons
              buttonType={data.Buttons}
              color={data.Color}
              isInterested={true}
            />
          )}
          <CTA data={data} />
        </div>
        {checkHowItWorksPage()
          ? data?.Image?.data && (
              <Image
                width={data.Image.data?.attributes?.width}
                height={data.Image.data?.attributes?.height}
                className="hidden sm:block w-[300px]"
                src={data.Image.data?.attributes?.url}
                alt="term"
                loader={svgLoader}
              />
            )
          : data?.Image?.data && (
              <div
                className={`${
                  data?.Title != "5. Vet incoming offers"
                    ? "w-full sm:w-1/2"
                    : "sm:px-20"
                } ${
                  checkSellPage() ? "hidden" : "flex"
                } sm:block justify-center`}
              >
                <Image
                  width={data.Image.data?.attributes?.width}
                  height={data.Image.data?.attributes?.height}
                  className={`${!checkSellPage() && "w-full"} ${
                    data.Buttons == "Sell & Browse"
                      ? "sm:h-[490px]"
                      : !checkSellPage() && "sm:h-[334px]"
                  }`}
                  src={data.Image.data?.attributes?.url}
                  alt="term"
                  loader={svgLoader}
                />
              </div>
            )}
      </div>
    </div>
  );
};

export default CenterBlock;
