import { useCallback, useState } from "react";
import Image from "next/image";

interface Props {
  authorInfo: {
    name: string;
    createdAt: Date;
    updatedAt: Date;
    slug: string;
    headshotUrl: string;
  };
}

const formatDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(date).toLocaleDateString(undefined, options);
};

export const AuthorPageDisplay = ({ authorInfo }: Props) => {
  const formattedCreatedAt = formatDate(authorInfo?.createdAt);
  const formattedUpdatedAt = formatDate(authorInfo?.updatedAt);

  return (
    <div className="relative z-[100]">
      <a
        className="flex items-center py-4 text-[#828282] "
        href={authorInfo?.slug}
        rel="noopener"
        target="_blank"
      >
        <div className="flex items-center relative ">
          <Image
            src={authorInfo?.headshotUrl}
            alt="Profile Picture"
            width={30}
            height={30}
            className="rounded-full w-[30px] h-[30px] overflow-hidden"
          />
        </div>
        <div className="flex items-center pl-3 md:text-[20px] text-[11px]">
          <div>{authorInfo.name}</div>
          <div className="px-1">&middot;</div>
          <div>{formattedCreatedAt}</div>
          <div className="px-1">|</div>
          <div>Updated: {formattedUpdatedAt}</div>
        </div>
      </a>
    </div>
  );
};
