import Image from "next/image";
import Link from "next/link";
import ContentButtons from "@/components/contentBlock/ContentButtons";
import {
  parseBgColor,
  parseTitleColor,
  parseSubTitleColor,
  parseContentColor,
  parseTitle,
  parseSimplifiedTitle,
  parseAlignment,
  parseContentBlockContent,
  getLinkCountFromContent,
  checkEsilyList,
  checkGuesswork,
  checkHowItWorksPage,
  checkUsedCarWarrantyGuidePage,
  checkBlogPage,
  checkCarTransportGuidePage,
  checkSequentTwoContent,
  checkCompetitorsPage,
  checkSellPage,
  checkFinancingPage,
  checkUsedCarSalesTaxGuidePage,
  checkUsedVehicleInspectionGuidePage,
  checkTranportCarsPage,
  svgLoader,
  checkHomePage,
  checkFinancingCarsPage,
  checkListingDetailsPage,
  checkDealNowPage,
  parseContainerAlignment,
  checkInsurancePage,
  isMobileDevice,
  parseOverrides,
  parseContentBlockImagesDimensions,
} from "@/libs/utils";
import UniversalHTag from "@/libs/UniversalHTag";
import { useEffect, useState } from "react";
import CTA from "../CTA";
import { AuthorPageDisplay } from "../authors/AuthorPageDisplay";

type Props = {
  data: any;
  nextData: any;
  isArticle: boolean;
  scrollToRunBuggy: Function;
  authorInfo?: any;
  index: number;
};

const LeftBlock = ({
  data,
  nextData,
  isArticle,
  scrollToRunBuggy,
  authorInfo,
  index,
}: Props) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  useEffect(() => {
    setIsMobile(isMobileDevice());
  }, []);

  return (
    <div
      className={`w-full ${
        data?.Width == "w Full" && parseBgColor(data.Color)
      } ${checkHowItWorksPage() && "sm:pt-[60px]"} ${
        !checkSellPage() &&
        !checkBlogPage() &&
        !checkSequentTwoContent(data, nextData) &&
        !checkEsilyList(data.Title, data.TitleStyle) &&
        !checkFinancingPage() &&
        !checkCarTransportGuidePage() &&
        !checkUsedCarSalesTaxGuidePage() &&
        data.Title !== "PrivateAuto vs Auction Sites" &&
        data.Title !== "PrivateAuto vs Marketplaces" &&
        !checkDealNowPage() &&
        "mb-8"
      } ${parseOverrides(data.Overrides?.data)}`}
    >
      <div
        className={`${!isArticle && "relative"} w-full ${
          checkDealNowPage() ? "sm:w-[694px]" : "lg:max-w-[1140px]"
        } block sm:flex ${
          checkDealNowPage() ? "px-10 sm:px-0" : "px-[8%] lg:px-0"
        } mx-auto ${
          checkDealNowPage()
            ? "pt-12"
            : data.Title == "Download the app."
            ? "pt-4"
            : data.SubTitle != "Selling privately. Simplified."
            ? !checkBlogPage()
              ? data.Title == "Sell Directly to Private Car Buyers"
                ? "pb-10 sm:py-10"
                : data.Title ==
                  "The Most Secure and Convenient Direct-to-Buyer Solution"
                ? ""
                : checkUsedCarWarrantyGuidePage()
                ? ""
                : checkFinancingPage() ||
                  checkCarTransportGuidePage() ||
                  checkUsedCarSalesTaxGuidePage() ||
                  data.Title === "PrivateAuto vs Auction Sites" ||
                  data.Title === "PrivateAuto vs Marketplaces"
                ? ""
                : checkSellPage()
                ? "pb-10 sm:py-10"
                : data.TextAlignment == "Left" && !checkHomePage()
                ? ""
                : "py-10"
              : checkEsilyList(data.Title, data.TitleStyle) && "pt-10"
            : ""
        } ${
          data.Title == "5. Vet incoming offers" && "items-center md:items-end"
        } ${checkTranportCarsPage() && "pb-10"} z-0`}
      >
        {checkHowItWorksPage() ? (
          <div className="flex sm:hidden w-[300px]">
            <Image
              width={data.Image.data?.attributes?.width}
              height={data.Image.data?.attributes?.height}
              className="block sm:hidden w-[300px] z-10"
              src={data.Image.data?.attributes?.url}
              alt="term"
              loader={svgLoader}
            />
          </div>
        ) : (
          checkEsilyList(data.Title, data.TitleStyle) &&
          data.Image != undefined &&
          data.Image != null &&
          data.Image.data != null && (
            <div className="w-full sm:w-1/2 flex sm:hidden justify-center sm:pl-6">
              <Image
                className={`${
                  data.Image.data.attributes.name == "phonesLanding.png"
                    ? "w-[540px] sm:h-[490px]"
                    : data.Title != undefined &&
                      data.Title != null &&
                      data.Title == "Download the app."
                    ? "w-[353px]"
                    : data.Image.data.attributes.name == "onAppInCar.png"
                    ? ""
                    : !checkSellPage() && checkCompetitorsPage()
                    ? ""
                    : "w-[425px]"
                } mt-6 sm:mt-0 z-10`}
                width={data.Image.data.attributes?.width}
                height={data.Image.data.attributes?.height}
                src={data.Image.data.attributes.url}
                alt={data.Image.data.attributes.alternativeText || ""}
                loader={svgLoader}
              />
            </div>
          )
        )}
        {data.Image?.data != null && checkSellPage() && (
          <div
            className={`${
              checkGuesswork(data.Title)
                ? "w-full sm:w-[40%]"
                : data.Title == "1. Gather necessary documentation" ||
                  data.Title == "3. List your car" ||
                  data.Title == "7. Schedule a test drive" ||
                  data.Title == "9. Get paid, instantaneously" ||
                  data.Title == "11. Cancel Insurance"
                ? "sm:px-20"
                : "w-full sm:w-1/2"
            } flex sm:hidden justify-center sm:pl-6 z-10`}
          >
            <Image
              className={`${
                data.Image.data.attributes.name == "phonesLanding.png"
                  ? "w-[540px]"
                  : data.Title != undefined &&
                    data.Title != null &&
                    data.Title == "Download the app."
                  ? "w-[353px]"
                  : data.Image.data.attributes.name == "onAppInCar.png"
                  ? ""
                  : !checkSellPage() && checkCompetitorsPage()
                  ? ""
                  : parseContentBlockImagesDimensions(data)
              } mt-6 sm:mt-0 z-10`}
              width={data.Image.data.attributes?.width || 375}
              height={data.Image.data.attributes?.height || 500}
              src={data.Image.data.attributes.url}
              alt={data.Image.data.attributes.alternativeText || ""}
              loader={svgLoader}
            />
          </div>
        )}
        <div
          className={`flex-1 ${!checkHowItWorksPage() && "w-full"} ${
            data.Image != undefined &&
            data.Image != null &&
            data.Image.data != null
              ? checkGuesswork(data.Title)
                ? "sm:w-[60%]"
                : !checkHowItWorksPage() && !checkSellPage()
                ? "sm:w-1/2"
                : ""
              : ""
          } ${
            checkGuesswork(data.Title)
              ? "px-[10%]"
              : !checkSellPage() &&
                !checkListingDetailsPage() &&
                !checkDealNowPage() &&
                "sm:px-8"
          } space-y-1 ${
            data.Title == "Download the app."
              ? "pt-16"
              : checkEsilyList(data.Title, data.TitleStyle) ||
                checkFinancingCarsPage()
              ? "pt-12"
              : checkUsedCarWarrantyGuidePage()
              ? ""
              : data.TextAlignment == "Left"
              ? index === 0
                ? "pt-4"
                : "pt-0"
              : ""
          } z-10`}
        >
          <div
            className={`flex items-center space-x-3 ${
              checkHomePage() && "pt-6"
            } ${parseContainerAlignment(data.TitleAlignment)}`}
          >
            {data.Title && (
              <UniversalHTag
                tag={data.TitleStyle}
                classname={`${
                  data.TitleStyle &&
                  (data.Title == "Easily list, meet, and get paid." &&
                  data.TitleStyle == "H2"
                    ? "text-[45px] font-bold leading-[55px]"
                    : parseTitle(data.TitleStyle, data.Title))
                } ${parseTitleColor(data.Color)} ${
                  checkBlogPage() || checkEsilyList(data.Title, data.TitleStyle)
                    ? "pt-0"
                    : data.Title != "1. Gather necessary documentation" &&
                      data.Title != "Sell Directly to Private Car Buyers" &&
                      data.Title !=
                        "The Most Secure and Convenient Direct-to-Buyer Solution" &&
                      "pt-10"
                } sm:pt-0 ${parseAlignment(data.TitleAlignment)} ${
                  checkEsilyList(data.Title, data.TitleStyle) && "td:w-[70%]"
                }  z-10`}
                content={
                  data.Title != undefined && data.Title != null && data.Title
                }
              />
            )}
            {checkBlogPage() && getLinkCountFromContent(data.Content) ? (
              <span className="text-[#999]">
                {getLinkCountFromContent(data.Content)}&nbsp;
                {getLinkCountFromContent(data.Content) == 1
                  ? "article"
                  : "articles"}
              </span>
            ) : (
              ""
            )}
          </div>
          {data.SubTitle && (
            <div className="flex">
              <UniversalHTag
                tag={data.SubTitleStyle}
                classname={`${parseSubTitleColor(data.Color)} ${
                  data.SubTitle == "Selling privately. Simplified."
                    ? parseSimplifiedTitle(data.SubTitleStyle)
                    : parseTitle(data.SubTitleStyle, data.SubTitle)
                } z-10`}
                content={data.SubTitle}
              />
            </div>
          )}
          {index === 0 && authorInfo && (
            <AuthorPageDisplay authorInfo={authorInfo} />
          )}
          <div
            className={`w-full text-xl font-normal ${parseContentColor(
              data.Color
            )} flex items-center mx-auto mt-8 space-x-8 ${parseAlignment(
              data.TextAlignment
            )} ${checkDealNowPage() && "text-[#4F4F4F] leading-[34px]"} z-10`}
          >
            {data.Content != null && (
              <div
                className={`w-full z-10 ${
                  checkEsilyList(data.Title, data.TitleStyle) && "sm:pr-10"
                } ${checkSellPage() && "w-[615px]"}`}
                dangerouslySetInnerHTML={{
                  __html: parseContentBlockContent(data.Content),
                }}
              ></div>
            )}
          </div>
          <ContentButtons buttonType={data.Buttons} />
          <CTA data={data} scrollToRunBuggy={scrollToRunBuggy} />
        </div>
        {!checkHowItWorksPage() &&
          data.Image != undefined &&
          data.Image != null &&
          data.Image.data != null && (
            <div
              className={`${
                checkGuesswork(data.Title)
                  ? "w-full sm:w-[40%]"
                  : data.Title == "1. Gather necessary documentation" ||
                    data.Title == "3. List your car" ||
                    data.Title == "5. Vet incoming offers" ||
                    data.Title == "7. Schedule a test drive" ||
                    data.Title == "9. Get paid, instantaneously" ||
                    data.Title == "11. Cancel Insurance"
                  ? "sm:px-20"
                  : "w-full sm:w-1/2"
              } ${
                checkEsilyList(data.Title, data.TitleStyle) || checkSellPage()
                  ? "hidden sm:flex"
                  : "flex"
              } justify-center sm:pl-6 ${checkFinancingCarsPage() && "py-8"} ${
                data.Title == "5. Vet incoming offers" && "h-[181px]"
              } z-10`}
            >
              <Image
                className={`${
                  data.Image.data.attributes.name == "phonesLanding.png"
                    ? "w-[540px]"
                    : data.Title != undefined &&
                      data.Title != null &&
                      data.Title == "Download the app."
                    ? "w-[353px]"
                    : data.Image.data.attributes.name == "onAppInCar.png"
                    ? ""
                    : !checkSellPage() && checkCompetitorsPage()
                    ? ""
                    : checkUsedVehicleInspectionGuidePage()
                    ? "w-full"
                    : data.Title == "Sell Directly to Private Car Buyers"
                    ? "sm:w-[400px]"
                    : checkSellPage()
                    ? ""
                    : checkInsurancePage()
                    ? "w-[425px]"
                    : parseContentBlockImagesDimensions(data)
                } ${
                  data.Title == "5. Vet incoming offers" && "h-[181px]"
                } mt-6 sm:mt-0 z-10`}
                width={data.Image.data.attributes?.width || 375}
                height={data.Image.data.attributes?.height || 500}
                src={data.Image.data.attributes.url}
                alt={data.Image.data.attributes.alternativeText || ""}
                loader={svgLoader}
              />
            </div>
          )}
        {data?.Video?.data != null && (
          <div className="w-full sm:w-[50%] flex justify-center">
            <div className="z-10">
              <video
                width={540}
                height={490}
                src={data.Video.data.attributes.url}
                className="w-[300px] h-[450px] object-fill"
                controls={true}
              />
            </div>
          </div>
        )}
        {checkHowItWorksPage() && data?.Image?.data && (
          <div className="hidden sm:flex w-[300px]">
            <Image
              width={data.Image.data?.attributes?.width}
              height={data.Image.data?.attributes?.height}
              className="hidden sm:block w-[300px] z-10"
              src={data.Image.data?.attributes?.url}
              alt="term"
              loader={svgLoader}
            />
          </div>
        )}
        {!isArticle ? (
          <div
            className={`absolute right-0 top-0 w-screen h-full ${parseBgColor(
              data.Color
            )} rounded-r-xl rounded-l-xl lg:rounded-l-none`}
          ></div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default LeftBlock;
