import { useAuthValue } from "@/contexts/contextAuth";
import {
  parseCTAColor,
  checkTranportCarsPage,
  isMobileDevice,
  buildSearchQueryUrl,
} from "@/libs/utils";
import Link from "next/link";
import React from "react";

type Props = {
  data: {
    CTAs: {
      data: Array<{
        attributes: {
          ActionType: string;
          Action: string;
          Label: string;
          CTAColor: string;
        };
      }>;
    };
  };
  scrollToRunBuggy?: any;
  searchParameters?: any;
};

const CTA = ({ data, scrollToRunBuggy, searchParameters }: Props) => {
  const { setIsSignup, setRedirectUrl } = useAuthValue();

  const firstCTA = data?.CTAs?.data[0]?.attributes;
  const actionType = firstCTA?.ActionType;
  const baseButtonClass = `text-base font-normal py-2 px-3 rounded cursor-pointer leading-6`;
  const buttonClass = `${parseCTAColor(
    firstCTA?.CTAColor
  )} ${baseButtonClass} ${isMobileDevice() ? "mb-5" : "mb-20"} relative z-10`;

  if (data?.CTAs?.data.length === 0) {
    return null;
  }

  return (
    <>
      {checkTranportCarsPage() ? (
        <button className={buttonClass} onClick={() => scrollToRunBuggy()}>
          {firstCTA?.Label}
        </button>
      ) : actionType === "New Tab" ? (
        <Link href={firstCTA?.Action} passHref target="_blank">
          <button className={buttonClass} rel="noopener noreferrer">
            {firstCTA?.Label}
          </button>
        </Link>
      ) : actionType === "Script" ? (
        <button
          className={buttonClass}
          dangerouslySetInnerHTML={{ __html: firstCTA?.Action }}
        />
      ) : actionType === "Open App And Navigate" ? (
        <button
          className={buttonClass}
          onClick={() => {
            setRedirectUrl(firstCTA?.Action);
            setIsSignup(true, { redirectUrl: firstCTA?.Action });
          }}
        >
          {firstCTA?.Label}
        </button>
      ) : actionType === "Vehicle Search Filters" ? (
        <Link href={`/buy${buildSearchQueryUrl(searchParameters)}`}>
          <button className={`${buttonClass} w-[250px]`}>
            {firstCTA?.Label}
          </button>
        </Link>
      ) : (
        <Link href={firstCTA?.Action}>
          <button className={buttonClass}>{firstCTA?.Label}</button>
        </Link>
      )}
    </>
  );
};

export default CTA;
