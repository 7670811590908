type Props = {
  active?: Boolean;
  index?: number;
  onClick?: Function;
};

const CustomDot = ({
  active = false,
  index = 0,
  onClick = () => {},
}: Props) => {
  return (
    <button
      className={`w-[30px] h-[8px] ${
        active ? "bg-[#2196f3]" : "bg-[#e9ecef]"
      } mx-2`}
      onClick={() => onClick(index)}
    ></button>
  );
};

export default CustomDot;
