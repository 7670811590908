import {
  parseAlignment,
  parseBgColor,
  parseContentBlockContent,
  parseContentColor,
  parseSubTitleColor,
  parseTitle,
  parseTitleColor,
  svgLoader,
} from "@/libs/utils";
import cx from "classnames";
import Image from "next/image";
import ContentButtons from "./ContentButtons";
import UniversalHTag from "@/libs/UniversalHTag";

type Props = {
  data: any;
  index: number;
};
export const WrappedText = ({ data }: Props) => {
  const isRight = data.Position == "Right";
  return (
    <div
      className={cx(
        "w-full overflow-x-hidden relative  lg:max-w-[1140px] block sm:flex vs:px-[8%] lg:px-0 mx-auto z-0",
        parseBgColor(data.Color)
      )}
    >
      <div className="p-4">
        <div
          className={cx("pb-4 vs:pb-0", {
            "vs:float-right vs:pl-12": isRight,
            "vs:float-left vs:pr-12": !isRight,
          })}
        >
          <Image
            width={400}
            height={400}
            src={data.Image.data?.attributes?.url}
            alt="term"
            loader={svgLoader}
          />
        </div>
        <div className="w-full z-10">
          <UniversalHTag
            tag={data.TitleStyle}
            classname={`${parseTitleColor(data.Color)} ${
              data.TitleStyle != undefined &&
              data.TitleStyle != null &&
              parseTitle(data.TitleStyle, data.Title)
            } ${parseAlignment(data.TitleAlignment)} z-10`}
            content={
              data.Title != undefined && data.Title != null ? data.Title : ""
            }
          />
          {data.SubTitle != null && data?.SubTitle != undefined && (
            <UniversalHTag
              tag={data.SubTitleStyle}
              classname={`${parseTitle(
                data.SubTitleStyle,
                data.SubTitle
              )} ${parseSubTitleColor(data.Color)} z-10`}
              content={data.SubTitle}
            />
          )}
          <div
            className={`text-xl font-normal ${parseContentColor(
              data.Color
            )} ${parseAlignment(data.TextAlignment)} z-10`}
          >
            {data.Content != null && (
              <div
                dangerouslySetInnerHTML={{
                  __html: parseContentBlockContent(data.Content),
                }}
              ></div>
            )}
          </div>
          <ContentButtons buttonType={data.Buttons} />
        </div>
      </div>
    </div>
  );
};
